<template>
  <UiSidePanel :model-value="modelValue" size="fullscreen" glued>
    <div class="bg-black-10">
      <div class="flex h-[72px] flex-row items-center justify-between bg-white px-10 py-4 shadow-down">
        <h5>Create Deal</h5>
        <div class="flex items-center space-x-4">
          <UiButtonBase id="cancel" type="secondary" size="medium" @click="emits('update:modelValue', false)">
            Cancel
          </UiButtonBase>
          <UiButtonBase id="create-deal" :disabled="loading || !data.deals.length" size="medium" @click="submit"
            >Create
          </UiButtonBase>
        </div>
      </div>
      <Transition name="fade">
        <div v-if="v$.$error" class="text-subhead-2 w-full rounded-b-xl bg-dm-90 px-10 py-1 text-white">
          Before creating a new unit, make sure to add all the required data for the current unit.
        </div>
      </Transition>
      <div class="h-full bg-black-10 px-10 py-6">
        <form id="deal-form" @submit.prevent>
          <div class="flex flex-col gap-6">
            <div class="flex flex-col gap-6 md:flex-row">
              <div class="w-full rounded-2xl bg-white p-6 md:w-4/12">
                <DealCreateType
                  v-model:type_id="data.type_id"
                  v-model:name="data.name"
                  :v="v$"
                  :is-off-plan
                  @reset:data="resetData"
                />
              </div>
              <div class="w-full rounded-2xl bg-white p-6 md:w-8/12">
                <DealCreateInfo v-model="data" :v="v$" :lead :is-off-plan />
              </div>
            </div>
            <div class="flex w-full flex-col gap-2">
              <div v-if="isOffPlan" class="rounded-2xl bg-white px-4 py-2">
                <UiTabsDynamicTabs
                  :active-tab="activeTabId"
                  :tabs="tabsToRender"
                  numbered-tabs
                  space-between
                  divider
                  add-tab-text="New unit"
                  @set-active-tab="setActiveTab"
                  @add-tab="addTab"
                  @close-tab="closeTab"
                />
              </div>

              <div class="flex flex-col gap-x-6 gap-y-3 md:flex-row">
                <div class="w-full rounded-2xl bg-white p-6 lg:w-7/12 xl:w-8/12">
                  <DealCreateUnitInfo v-model="data" :v="v$" :is-off-plan :active-tab-id-index />
                </div>
                <div class="w-full rounded-2xl bg-white p-6 lg:w-5/12 xl:w-4/12">
                  <DealCreatePayment v-model="data" :v="v$" :is-off-plan :active-tab-id-index />
                </div>
              </div>
            </div>
            <div class="w-full rounded-2xl bg-white p-6">
              <DealCreateDocuments :key="data.type_id" v-model="data" :v="v$" :is-off-plan :active-tab-id-index />
            </div>
          </div>
        </form>
      </div>
    </div>
  </UiSidePanel>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { ROLES, FINANCE_COMMISSION_TYPES, CLOSE_STEPS, TAGS, FINANCE_CONTACT_TYPES, LEAD_SOURCE_IDS } from '@/constants'
import { useUiStore } from '~/store/ui'
import { useAuthStore } from '@/store/auth'
import type { InputItem, Lead, Stage, LibraryItem, CreatingNewDealTab, DealCreationDTO, Team, User } from '@/types'

const uiStore = useUiStore()
const authStore = useAuthStore()

const { t: $t } = useI18n()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
  stage: Stage
}
const props = defineProps<Props>()

const loading = ref(false)
const brokerageCommissionTypeId = ref()
const newDealId = ref()
const projectItems = ref<InputItem[]>([])
const developerItems = ref<InputItem[]>([])
const sourceItems = ref<InputItem[]>([])
const typeItems = ref<InputItem[]>([])
const agentItems = ref<InputItem[]>([])
const managerItems = ref<InputItem[]>([])
const activeTabId = ref<number | undefined | null>(0)
const tabIndex = ref(0)
const teamManager = ref()

const isReferralLead = computed(() => !!props.lead.tags.find((t) => t.code === TAGS.REFERRAL))
const activeTabIdIndex = computed(() => tabs.value.findIndex(({ id }) => activeTabId.value === id))
// TODO: change to the computed condition when it's time to implement the other types. For now, they all behave as off-plan
const isOffPlan = ref(true)
// const isOffPlan = computed(() => data.value.type_id === OFF_PLAN_TYPE_ID)

const dealItem = {
  project_id: undefined,
  unit_number: undefined,
  unit_price: undefined,
  commission: {
    percent: undefined,
    type_id: undefined,
  },
  developer_id: undefined,
  booking_date: undefined,
  down_payment_amount: undefined,
  down_payment_due_date: undefined,
  down_payment_note: '',
  documents: [],
}

const data = ref<DealCreationDTO>({
  name: props.lead.name || '',
  agent_id: undefined,
  manager_id: undefined,
  type_id: undefined,
  source_id: undefined,
  deals: [cloneDeep(dealItem)],
})

const primaryContactType = ref<LibraryItem>()

const validateCommission = (value: { percent: number }) => {
  return value.percent > 0 && value.percent <= 100 && Number.isInteger(value.percent)
}

const rules = computed(() => ({
  name: { required: helpers.withMessage('The name is required', required) },
  type_id: { required: helpers.withMessage('The type is required', required) },
  agent_id: { required: helpers.withMessage('The agent is required', required) },
  deals: {
    $each: helpers.forEach({
      developer_id: { required: helpers.withMessage('The developer is required', required) },
      booking_date: { required: helpers.withMessage('The booking date is required', required) },
      project_id: { required: helpers.withMessage('The project is required', required) },
      unit_number: { required: helpers.withMessage('The unit number is required', required) },
      unit_price: { required: helpers.withMessage('The unit price is required', required) },
      commission: { validateCommission: helpers.withMessage('Commission percentage is not valid', validateCommission) },
    }),
  },
}))

const v$ = useVuelidate(rules, data)

// Tabs

const tabs = ref<CreatingNewDealTab[]>([
  {
    id: tabIndex.value,
    text: $t('unit'),
    error: false,
  },
])

const setTabValidity = () => {
  let tabsHasError: null | number = null
  data.value.deals.forEach((deal, i) => {
    const fieldWithError = Object.keys(deal).find((key) => {
      return useGetFieldErrorsByIndex(v$.value, 'deals', key, i)
    })
    if ((tabsHasError === null || activeTabId.value === tabs.value[i].id) && fieldWithError) {
      tabsHasError = tabs.value[i].id
    }
    tabs.value[i].error = !!fieldWithError
  })

  if (tabsHasError && tabsHasError !== activeTabId.value) {
    setActiveTab(tabsHasError)
  }
}

const tabsToRender = computed(() => tabs.value.filter((t) => (t.condition ? t.condition() : true)))

const addTab = () => {
  addDeal()
  tabs.value.push({
    id: ++tabIndex.value,
    text: $t('unit'),
    error: false,
  })
  setActiveTab(tabIndex.value)
}

const closeTab = (id: number) => {
  const deletedTabIndex = tabs.value.findIndex((item: CreatingNewDealTab) => item.id === id)
  deleteDeal(deletedTabIndex)
  tabs.value.splice(deletedTabIndex, 1)
  if (activeTabId.value === id) {
    nextTick(() => {
      activeTabId.value = tabs.value.length ? tabs.value[0].id : null
    })
  }
}

const setActiveTab = (id: number) => {
  activeTabId.value = null
  setTimeout(() => {
    activeTabId.value = id
  }, 200)
}

onNuxtReady(async () => {
  loading.value = true
  try {
    await Promise.all([
      getProjectItems(),
      getDeveloperItems(),
      getSourceItems(),
      getTypeItems(),
      getCommissionTypes(),
      getUserItems(),
      getContactTypes(),
      getTeams(),
    ])
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
  setDefaultValues()
  loading.value = false
})

const setDefaultValues = () => {
  data.value.source_id = isReferralLead.value
    ? LEAD_SOURCE_IDS.PARTNER
    : props.lead.is_digital
    ? LEAD_SOURCE_IDS.DIGITAL
    : LEAD_SOURCE_IDS.OWN
  data.value.agent_id = authStore.getIsAgent || authStore.getIsManager ? authStore.getUser?.id! : undefined
  data.value.manager_id = authStore.getIsAgent ? teamManager.value?.id : authStore.getUser?.id!
}

const getProjectItems = async () => {
  projectItems.value = await useGetFinanceProjectsItems()
}

const getDeveloperItems = async () => {
  developerItems.value = await useGetFinanceDevelopersItems()
}

const getSourceItems = async () => {
  const sources = await useFinanceDealSources()
  sourceItems.value = useSerializeLibraryItems(sources)
}

const getTypeItems = async () => {
  typeItems.value = await useGetFinanceDealTypesItems()
}

const getTeams = async () => {
  const response = await useGetTeams()
  const team = response.data.find((t: Team) => t.id === authStore.getUser?.teams[0]?.id)
  teamManager.value = team?.users.find((u: User) => u.is_manager)
}

const getCommissionTypes = async () => {
  const response = await useFinanceCommissionTypes()
  brokerageCommissionTypeId.value = response.find((c) => c.code === FINANCE_COMMISSION_TYPES.BROKERAGE)?.id
}

const getUserItems = async () => {
  agentItems.value = await useGetAgentsAndManagersItems()
  managerItems.value = await useGetManagersItems()
  const users = await useGetUsers()
  const adminUsers = useSerializeLibraryItems(users.filter((user) => user.role?.code === ROLES.ADMIN))
  managerItems.value.push(...adminUsers)
}

const getContactTypes = async () => {
  const types = await useFinanceContactTypes()
  primaryContactType.value = types.find((t) => t.code === FINANCE_CONTACT_TYPES.PRIMARY)
}

const goToDeal = () => {
  navigateTo(`/deals/${newDealId.value}`, {
    open: {
      target: '_blank',
    },
  })
}

const addDeal = () => {
  data.value.deals.push(cloneDeep(dealItem))
}

const deleteDeal = (index: number) => {
  data.value.deals.splice(index, 1)
}

const resetData = () => {
  data.value = {
    name: props.lead.name || '',
    agent_id: undefined,
    manager_id: undefined,
    type_id: data.value.type_id,
    source_id: undefined,
    deals: [cloneDeep(dealItem)],
  }
  activeTabId.value = 0
  tabIndex.value = 0
  v$.value.$reset()
}

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) {
    setTabValidity()
    return
  }
  try {
    loading.value = true

    const payload = {
      ...data.value,
      deals: data.value.deals.map((deal) => ({
        ...deal,
        contact: { name: data.value.name, type_id: primaryContactType.value?.id },
        commission: { percent: deal.commission.percent, type_id: brokerageCommissionTypeId.value },
        documents: deal.documents?.filter((d) => d.file),
      })),
    }
    const payloadClosing = {
      pipeline_stage_id: props.stage?.id,
      pipeline_step_id: props.stage.steps?.find((s) => s.code === CLOSE_STEPS.WON)?.id,
    }

    const response = await useCreateNewDeal(props.lead.id, payload)

    if (response) {
      newDealId.value = response.data.id
      const responseClosing = await useCloseLead(props.lead.id, payloadClosing)
      uiStore.showSnackBanner(`Deal(s) ${props.lead.name} created.`, 'success', goToDeal, 'Open deal')
      emits('input', responseClosing.data)
      emits('update:modelValue', false)
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
